import { root } from './MultiLineText.styles'
import { IMultiLineTextProps } from './MultiLineText.types'

const MultiLineText = ({
  alignment = 'inherit',
  color = 'inherit',
  tag: Tag = 'pre',
  children,
  margin,
  size,
  styles
}: IMultiLineTextProps) => (
  <Tag
    data-testid='multiline-text'
    css={root({
      alignment,
      color,
      margin,
      size,
      styles
    })}
  >
    {children}
  </Tag>
)

export default MultiLineText
