import theme from 'utils/theme'

const { colors } = theme

const QuarterCircleGrey = () => (
  <div css={{ position: 'absolute', bottom: 0, right: 0 }}>
    <svg
      width='263'
      height='200'
      viewBox='0 0 263 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_4878_51824)'>
        <path
          d='M239.022 258.645L431.275 67.1419C434.129 69.7739 436.93 72.4058 439.678 75.0378L442.929 78.3541L261.986 258.645L239.022 258.645Z'
          fill='url(#paint0_linear_4878_51824)'
        />
        <path
          d='M190.239 258.645L404.294 45.4545C408.76 48.5339 413.146 51.7975 417.507 55.1928L213.23 258.645L190.239 258.645Z'
          fill='url(#paint1_linear_4878_51824)'
        />
        <path
          d='M92.7234 258.645L339.177 13.1864C344.832 15.0638 350.417 17.1255 355.932 19.3715L115.609 258.645L92.7234 258.645Z'
          fill='url(#paint2_linear_4878_51824)'
        />
        <path
          d='M44.2638 112.594C33.851 127.876 25.1322 144.237 18.2601 161.391L162.047 18.2374C144.796 25.0766 128.342 33.7603 112.973 44.1361L44.2638 112.594Z'
          fill='url(#paint3_linear_4878_51824)'
        />
        <path
          d='M0.0245056 258.646C0.0245055 257.031 0.0245053 255.426 0.0245052 253.829L254.882 0.0274067C262.316 0.0274061 269.733 0.264283 277.133 0.738037L18.153 258.672L0.0245056 258.646Z'
          fill='url(#paint4_linear_4878_51824)'
        />
        <path
          d='M6.61079 198.741C4.49699 207.877 2.88264 217.12 1.77478 226.43L227.352 1.76413C218.005 2.87198 208.724 4.47981 199.551 6.58065L6.61079 198.741Z'
          fill='url(#paint5_linear_4878_51824)'
        />
        <path
          d='M43.9758 258.645L300.154 3.50061C306.523 4.55341 312.892 5.86939 319.208 7.39594L66.9404 258.645L43.9758 258.645Z'
          fill='url(#paint6_linear_4878_51824)'
        />
        <path
          d='M141.507 258.645L373.611 27.4516C378.632 29.9783 383.592 32.6717 388.489 35.5318L164.472 258.645L141.507 258.645Z'
          fill='url(#paint7_linear_4878_51824)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_4878_51824'
          x1='340.975'
          y1='258.645'
          x2='340.975'
          y2='67.1419'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={colors.grey10} />
          <stop offset='1' stopColor={colors.grey5} />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4878_51824'
          x1='303.873'
          y1='258.645'
          x2='303.873'
          y2='45.4544'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={colors.grey10} />
          <stop offset='1' stopColor={colors.grey5} />
        </linearGradient>
        <linearGradient
          id='paint2_linear_4878_51824'
          x1='224.328'
          y1='258.645'
          x2='224.328'
          y2='13.1864'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={colors.grey10} />
          <stop offset='1' stopColor={colors.grey5} />
        </linearGradient>
        <linearGradient
          id='paint3_linear_4878_51824'
          x1='90.1535'
          y1='161.391'
          x2='90.1535'
          y2='18.2374'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={colors.grey10} />
          <stop offset='1' stopColor={colors.grey5} />
        </linearGradient>
        <linearGradient
          id='paint4_linear_4878_51824'
          x1='138.579'
          y1='258.672'
          x2='138.579'
          y2='0.0274169'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={colors.grey10} />
          <stop offset='1' stopColor={colors.grey5} />
        </linearGradient>
        <linearGradient
          id='paint5_linear_4878_51824'
          x1='114.563'
          y1='226.43'
          x2='114.563'
          y2='1.76412'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={colors.grey10} />
          <stop offset='1' stopColor={colors.grey5} />
        </linearGradient>
        <linearGradient
          id='paint6_linear_4878_51824'
          x1='181.592'
          y1='258.645'
          x2='181.592'
          y2='3.50062'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={colors.grey10} />
          <stop offset='1' stopColor={colors.grey5} />
        </linearGradient>
        <linearGradient
          id='paint7_linear_4878_51824'
          x1='264.998'
          y1='258.645'
          x2='264.998'
          y2='27.4516'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={colors.grey10} />
          <stop offset='1' stopColor={colors.grey5} />
        </linearGradient>
        <clipPath id='clip0_4878_51824'>
          <rect
            width='515'
            height='258.644'
            fill='white'
            transform='translate(515 258.645) rotate(180)'
          />
        </clipPath>
      </defs>
    </svg>
  </div>
)

export default QuarterCircleGrey
