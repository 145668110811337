import Icon from 'lib/ui/Icon'
import Image from 'next/image'
import theme from 'utils/theme'
import { breakpoints } from 'utils/theme/mediaQuery'

import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'

const { mediaQuery, size, spacing } = theme
interface ILogo {
  context?: 'default' | 'dashboard'
  layout?: string
  height?: number
  maxHeight?: number
  width?: number
  partner?: string
  logo?: string
  defaultAltLogo?: JSX.Element
}

const RenderLogo = ({
  context = 'default',
  height,
  logo,
  partner,
  width,
  defaultAltLogo
}: ILogo) => {
  if (logo)
    return (
      <div
        css={{
          position: 'relative',
          width: '100%',
          height: '100%',
          margin: context === 'default' ? 0 : 'auto',
          maxWidth:
            context === 'default' ? `${size(width)} !important` : size(18.5),
          [mediaQuery('xs')]: {
            maxWidth: size(20)
          },
          [mediaQuery('sm')]: {
            maxWidth: size(width)
          },
          minHeight: size(height),
          img: {
            objectFit: 'contain',
            objectPosition: context === 'default' ? 'left center' : 'center',
            padding: context === 'default' ? 0 : spacing(1),
            [mediaQuery('lg')]: {
              padding: 0
            }
          }
        }}
      >
        <Image
          fill
          priority
          src={logo}
          alt={`${partner} logo`}
          sizes={
            context === 'dashboard'
              ? `185px, (min-width: ${breakpoints.xs}) 200px, (min-width: ${breakpoints.sm}) 250px)`
              : '250px'
          }
        />
      </div>
    )
  return (
    <div className='alt-logo'>
      {defaultAltLogo ?? (
        <Icon
          name={'altLogoText'}
          color='alt'
          viewBox='0 0 128 26'
          height={5}
          width={22}
        />
      )}
    </div>
  )
}

const PartnerLogo = ({
  height = 7.5,
  width = 25,
  context,
  defaultAltLogo
}: ILogo) => {
  const { company } = useCompanyAndInvoiceContext()
  const logo = company?.paymentsPortalCustomization?.logoUrl

  return (
    (!!company || company === null) && (
      <RenderLogo
        logo={logo}
        partner={company?.name}
        height={height}
        width={width}
        context={context}
        defaultAltLogo={defaultAltLogo}
      />
    )
  )
}
export default PartnerLogo
