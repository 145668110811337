import Box from 'lib/ui/Box'
import Text from 'lib/ui/Text'

import { RequiredPartnerProps } from './types'

const PartnerDetails = ({ partner }: { partner: RequiredPartnerProps }) => (
  <Box padding={0} margin={[0.5, 0]}>
    <Text color='grey25' size='sm'>
      {partner?.name}
    </Text>
    <Text color='grey25' size='sm'>
      {partner?.streetAddress}
    </Text>
    <Text color='grey25' size='sm'>
      {partner?.subPremise}
    </Text>
    <Text color='grey25' size='sm'>
      {partner?.city}, {partner?.state} {''} {partner?.postalCode}
    </Text>
  </Box>
)

export default PartnerDetails
