import { formatCurrency, formatNumber, formatRate } from 'utils/numbers'
import { formatInvoiceStatusText } from 'utils/status'
import { ColorProps } from 'utils/types'

import Footer from './Footer'
import * as css from './styles'
import { IInvoice, IPartner } from './types'
import PartnerLogo from '@/ui/PartnerLogo'
import { formatDate } from '@/utils/dates/format'

const InvoicePdfLayout = ({
  partner,
  invoice,
  brandColor
}: {
  partner: IPartner
  invoice: IInvoice
  brandColor?: ColorProps
}) => {
  let hasLines = invoice?.lineItems?.length > 0
  let lineItems = []

  if (hasLines) {
    lineItems = [...invoice.lineItems]
  }
  if (!hasLines && invoice?.productName) {
    hasLines = true
    lineItems.push({
      description: invoice.productName,
      quantity: 1,
      extendedAmountPerItem: invoice.amount
    })
  }

  let hasCategories = false
  let subtotal = 0
  invoice?.lineItems?.forEach(li => {
    subtotal += li.quantity * li.extendedAmountPerItem
    if (li.category) {
      hasCategories = true
    }
  })

  let taxAmountPercent = 0
  if (subtotal) {
    taxAmountPercent = (invoice?.totalTaxAmount * 100) / subtotal
  }

  let discountAmountPercent = 0
  if (subtotal) {
    discountAmountPercent = (invoice?.totalDiscountAmount * 100) / subtotal
  }

  return (
    <table css={css.pageTable({ brandColor })}>
      <thead>
        <tr>
          <th>
            <div></div>
          </th>
        </tr>
      </thead>
      <tbody>
        <td>
          <div css={css.headerWrapper}>
            <div className='invoice-data'>
              <h1>Invoice #{invoice?.invoiceNumber}</h1>
              <div>
                <span>Bill</span>
                <span>{invoice?.customer?.name}</span>
              </div>
              <div>
                <span>Created on</span>
                <span>{formatDate(invoice?.createdAt, 'M/D/YY')}</span>
              </div>
              <div>
                <span>Status</span>
                <span>
                  {invoice?.status && formatInvoiceStatusText(invoice.status)}
                </span>
              </div>
              <div>
                <span>Due on</span>
                <span>{formatDate(invoice?.dueDate, 'M/D/YY')}</span>
              </div>
            </div>

            <div className='partner-data'>
              <div className='logo-wrapper'>
                <PartnerLogo height={3} />
              </div>
              <div>
                <span>{partner?.streetAddress}</span>
                <span>{partner?.subPremise}</span>
                <span>
                  {partner?.city}, {partner?.state} {''} {partner?.postalCode}
                </span>
              </div>
            </div>
          </div>

          <div css={css.detailsWrapper({ hasLines })}>
            <h2>Invoice details</h2>

            <table className='lines'>
              <thead>
                <tr>
                  {hasCategories && <th>Category</th>}
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {!hasLines && (
                  <tr>
                    {hasCategories && <td>-</td>}
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
                {lineItems?.map((li, i) => (
                  <tr key={`line-item-${i}`}>
                    {hasCategories && <td>{li.category || '-'}</td>}
                    <td>
                      <pre>{li.description}</pre>
                    </td>
                    <td>{formatNumber({ amount: li.quantity, places: 2 })}</td>
                    <td>
                      {formatCurrency({ amount: li.extendedAmountPerItem })}
                    </td>
                    <td>
                      {formatCurrency({
                        amount: li.quantity * li.extendedAmountPerItem
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {hasLines ? (
              <table css={css.resumeTableWithLines}>
                <tbody>
                  <tr>
                    <td>
                      <div className='resume'>
                        <div>
                          <span>Subtotal</span>
                          <span>{formatCurrency({ amount: subtotal })}</span>
                        </div>
                        {invoice?.totalTaxAmount > 0 && (
                          <div>
                            <span>
                              Sales tax{' '}
                              {formatRate({
                                amount: taxAmountPercent,
                                places: 2
                              })}
                              %
                            </span>
                            <span>
                              {formatCurrency({
                                amount: invoice.totalTaxAmount
                              })}
                            </span>
                          </div>
                        )}
                        {invoice?.totalDiscountAmount > 0 && (
                          <div>
                            <span>
                              Discount{' '}
                              {formatRate({
                                amount: discountAmountPercent,
                                places: 2
                              })}
                              %
                            </span>
                            <span>
                              {formatCurrency({
                                amount: invoice.totalDiscountAmount
                              })}
                            </span>
                          </div>
                        )}
                        <div>
                          <span>Total due</span>
                          <span>
                            {formatCurrency({ amount: invoice?.amount })}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table css={css.resumeTableWithoutLines}>
                <tbody>
                  <tr>
                    <td>
                      <div className='resume'>
                        <span>Total due</span>
                        <span>
                          {formatCurrency({ amount: invoice?.amount })}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </td>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <Footer />
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

export default InvoicePdfLayout
