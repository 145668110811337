import { defaultColor, headerStyles } from './styles'
import { HeaderProps } from './types'

const Header = ({ brandColor }: HeaderProps) => (
  <div
    css={{
      ...headerStyles,
      backgroundColor: brandColor ? brandColor.hex : defaultColor
    }}
  ></div>
)

export default Header
