import Icon from 'lib/ui/Icon'
import theme from 'utils/theme'

const { spacing } = theme

const PoweredBy = () => (
  <div css={{ position: 'absolute', bottom: spacing(3), left: spacing(3) }}>
    <Icon
      name='altLogoPoweredBy'
      color='grey20'
      width={16.3}
      height={3.6}
      viewBox='0 0 163 36'
    />
  </div>
)

export default PoweredBy
