import Icon from 'lib/ui/Icon'

import { IDownloadPdf } from '../types'

const image = {
  maxWidth: '200px',
  maxHeight: '100px'
}

const Logo = ({ masthead }: Pick<IDownloadPdf, 'masthead'>) => {
  const { logo, logoContent } = masthead

  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        left: 550,
        width: '225px',
        height: '300px'
      }}
    >
      <div
        css={{
          height: 'inherit',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flexDirection: 'column',
          overflow: 'hidden',
          wordBreak: 'break-word',
          gap: '8px'
        }}
      >
        {logo ? (
          <img alt='logo' src={logo} style={image} />
        ) : (
          <Icon
            name='altLogoText'
            color='alt'
            viewBox='0 0 128 26'
            height={5}
            width={22}
          />
        )}
        {logoContent}
      </div>
    </div>
  )
}
export default Logo
