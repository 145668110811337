import { createStyles } from 'utils/css'
import colors from 'utils/theme/colors'
import { ColorProps } from 'utils/types'

export const reactToPrintPageStyle = `
  @page {
    margin: -1px;
  }
  @media print {
    body, html {
      margin: 0;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
      background-color: ${colors.surface100};
    }
  }
`

export const showOnlyOnPrint = createStyles(() => ({
  display: 'none',
  '@media print': {
    display: 'block'
  }
}))

export const pageTable = ({ brandColor }: { brandColor: ColorProps }) =>
  createStyles(({ colors, size, spacing }) => ({
    width: '100%',
    height: '100vh',
    backgroundColor: colors.surface100,
    borderCollapse: 'collapse',
    tableLayout: 'fixed',

    '>thead': {
      th: {
        paddingBottom: '24px',
        '>div': {
          backgroundColor: brandColor ? brandColor.hex : colors.primary400,
          height: size(1.6),
          width: '100%'
        }
      }
    },

    '>tbody': {
      '>td': {
        padding: `0 ${spacing(2.5)}`
      }
    },

    '>tfoot': {
      td: {
        paddingTop: '24px'
      }
    }
  }))

export const footer = createStyles(({ colors }) => ({
  background: colors.surface200,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  width: '100%',
  left: 0,
  bottom: 0,
  padding: '8px 0',
  height: '40px'
}))

export const headerWrapper = createStyles(({ colors }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: '15px',
  color: colors.surface500,
  paddingBottom: '24px',
  borderBottom: `1px solid ${colors.surface300}`,

  'div.invoice-data': {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    gap: '15px',

    h1: {
      gridColumn: 'span 4',
      fontWeight: '700',
      fontSize: '32px',
      lineHeight: '48px'
    },

    '>div': {
      height: '30px',
      display: 'flex',
      flexDirection: 'column',

      'span:first-of-type': {
        color: colors.surface400,
        fontSize: '10px',
        lineHeight: '15px'
      },

      'span:last-of-type': {
        fontWeight: '700',
        fontSize: '12px',
        lineHeight: '18px'
      }
    }
  },

  'div.partner-data': {
    justifySelf: 'end',
    textAlign: 'right',

    div: {
      display: 'grid',
      justifySelf: 'end',
      fontSize: '10px',
      lineHeight: '15px',
      color: colors.surface400
    },

    '.logo-wrapper': {
      minWidth: '100px',
      marginBottom: '8px',
      svg: {
        height: '30px',
        width: '100px'
      }
    }
  }
}))

export const detailsWrapper = ({ hasLines }: { hasLines: boolean }) =>
  createStyles(({ colors }) => ({
    paddingTop: '24px',
    display: 'grid',

    h2: {
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: '27px'
    },

    'table.lines': {
      marginTop: '16px',
      width: '100%',
      maxHeight: '70%',
      fontSize: '9px',
      lineHeight: '13px',
      borderCollapse: 'collapse',

      thead: { display: 'table-header-group' },
      tr: { 'break-inside': 'avoid', 'break-before': 'auto' },

      th: {
        paddingBottom: '8px',

        '&:nth-child(2),&:nth-child(3)': {
          textAlign: 'center'
        },
        '&:last-child': {
          textAlign: 'right'
        }
      },

      td: {
        fontWeight: '700',
        color: colors.surface400,
        padding: hasLines ? '8px 0' : '16px 0 0 0',
        borderBottom: hasLines ? `1px solid ${colors.surface300}` : 'none',

        '&:nth-child(2),&:nth-child(3)': {
          textAlign: 'center',
          padding: hasLines ? '8px' : '16px 0 0 0'
        },
        '&:last-child': {
          textAlign: 'right'
        },

        pre: {
          whiteSpace: 'pre-line',
          wordWrap: 'break-word'
        }
      }
    }
  }))

export const resumeTableWithLines = createStyles(() => ({
  td: {
    display: 'grid'
  },

  'div.resume': {
    width: '200px',
    justifySelf: 'end',
    marginTop: '16px',
    fontSize: '10px',
    lineHeight: '15px',
    color: colors.surface500
  },

  'div:not(.resume)': {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    padding: '0 0 8px 8px',

    'span:last-of-type': {
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '18px',
      justifySelf: 'end'
    },

    '&:last-of-type': {
      paddingTop: '8px',
      borderTop: `1px solid ${colors.surface300}`,
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '21px'
    }
  }
}))

export const resumeTableWithoutLines = createStyles(() => ({
  '.resume': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '200px',
    paddingTop: '8px',
    borderTop: `1px solid ${colors.surface300}`,
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '21px'
  }
}))
