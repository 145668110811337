import { AlternativePaymentsLogo } from 'lib/ui/Logo'

import { defaultColor, footerStyles } from './styles'
import { FooterProps } from './types'

const Footer = ({ brandColor, icon }: FooterProps) => (
  <footer
    css={{
      ...footerStyles,
      backgroundColor: brandColor ? brandColor.hex : defaultColor
    }}
  >
    {icon || <AlternativePaymentsLogo />}
  </footer>
)
export default Footer
