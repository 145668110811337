import { createStyles } from 'utils/css'

import { IDivider } from './Divider.types'

export const root = ({ color, width, margin, height = 1 }: IDivider) =>
  createStyles(({ colors, spacing }) => ({
    margin: spacing([margin, 0]),
    width: width,
    borderTop: `${height + 'px'} solid ${colors[color]}`
  }))
