import PoweredBy from 'lib/ui/Icon'

import * as css from './styles'

const Footer = () => (
  <footer css={css.footer}>
    <PoweredBy
      name='poweredByV2'
      width={6.2}
      height={2.4}
      viewBox='0 0 100 40'
    />
  </footer>
)

export default Footer
