import { CSSProperties } from 'react'

import { size } from 'utils/theme'
import colors from 'utils/theme/colors'
import { spacing } from 'utils/theme/spacing'

export const defaultColor = colors.primary400

export const headerStyles: CSSProperties = {
  minHeight: size(1.6),
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%'
}

export const footerStyles: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  width: '100%',
  left: 0,
  bottom: 0,
  padding: spacing(1.5),
  minHeight: size(5.125) // 82px
}

export const content: CSSProperties = {
  padding: spacing(2.5),
  zIndex: 1,
  position: 'relative'
}

export const contentColumn: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%'
}

export const pageTitleStyles: CSSProperties = {
  fontWeight: 700,
  fontSize: size(3.2)
}

export const pageCounterStyles = {
  position: 'absolute',
  right: 40,
  bottom: 40,
  fontSize: '14px',
  zIndex: 1
}
