import { useState } from 'react'

import CheckmarkFilled from '@carbon/icons-react/lib/CheckmarkFilled'
import CloseFilled from '@carbon/icons-react/lib/CloseFilled'
import ErrorFilled from '@carbon/icons-react/lib/ErrorFilled'
import WarningFilled from '@carbon/icons-react/lib/WarningFilled'
import Button from 'lib/ui/Button'
import Grid from 'lib/ui/Grid'
import Text from 'lib/ui/Text'
import { ToastType } from 'react-hot-toast'
import theme, { size } from 'utils/theme'
import colors from 'utils/theme/colors'

const ToastMessage = ({
  type,
  title,
  subtitle,
  Icon,
  action
}: {
  type: ToastType
  Icon?: JSX.Element
  title?: string
  subtitle: JSX.Element | string
  action?: JSX.Element
}) => {
  const DefaultIcon: { [key: string]: JSX.Element } = {
    ['success']: <CheckmarkFilled size={24} />,
    ['loading']: <WarningFilled size={24} />,
    ['error']: <ErrorFilled size={24} />
  }
  const defaultTitle = type.charAt(0).toUpperCase() + type.slice(1)

  const [showActionButtons, setShowActionButtons] = useState<boolean>(true)

  const gridColumns = action ? '1fr 1fr' : '1fr'
  return (
    <>
      <Grid
        columns={gridColumns}
        minWidth={0}
        styles={{
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {Icon || DefaultIcon[type]}
          <Text
            size='md'
            styles={{
              fontWeight: 700,
              margin: theme.spacing([0.5, 0.5])
            }}
          >
            {title || defaultTitle}
          </Text>
        </div>
        {action && showActionButtons && (
          <div style={{ justifySelf: 'end' }}>
            {action}

            {showActionButtons && (
              <Button
                variant='text'
                onClick={() => {
                  setShowActionButtons(false)
                }}
              >
                <CloseFilled size={24} />
              </Button>
            )}
          </div>
        )}
      </Grid>

      <div
        css={{
          lineHeight: size(1.5),
          fontSize: '12px',
          color: colors.surface400
        }}
      >
        {subtitle}
      </div>
    </>
  )
}
export default ToastMessage
