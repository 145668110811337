import {
  CustomerFindInvoiceFragment,
  Customer
} from 'graph/generated/payments/graphql-types'
import Divider from 'lib/ui/Divider'
import { downloadPdfCss } from 'lib/ui/DownloadPdf'
import GreyBox from 'lib/ui/DownloadPdf/GreyBox'
import TextHighlight from 'lib/ui/DownloadPdf/TextHighlight'
import Heading from 'lib/ui/Heading'
import MultiLineText from 'lib/ui/MultiLineText'
import Text from 'lib/ui/Text'
import { formatCurrency } from 'utils/numbers'
import { formatInvoiceStatusText } from 'utils/status'

import { formatDate } from '@/utils/dates/format'

interface IInvoicePdfLayout {
  invoice: CustomerFindInvoiceFragment
}

const InvoicePdfLayout = ({ invoice }: IInvoicePdfLayout) => {
  const customer = invoice?.customer as Customer
  return (
    <>
      <div>
        <div css={{ ...downloadPdfCss.topRow }}>
          <GreyBox>
            <Text color='grey25' size='md' styles={downloadPdfCss.fontStyles}>
              Bill to
            </Text>
            <Text color='grey25' size='sm'>
              {customer?.name}
            </Text>
            <Text color='grey25' size='sm'>
              {customer?.streetAddress}
            </Text>
            <Text color='grey25' size='sm'>
              {customer?.subPremise}
            </Text>
            <Text color='grey25' size='sm'>
              {customer?.city} {customer?.state} {customer?.postalCode}
            </Text>
          </GreyBox>

          <div
            css={{
              ...downloadPdfCss.topRow,
              justifyContent: 'space-around'
            }}
          >
            <div>
              <Text size='xs' color='grey25'>
                Created on
              </Text>
              <TextHighlight>
                {formatDate(invoice?.createdAt, 'MMM DD, YYYY')}
              </TextHighlight>
            </div>
            <div>
              <Text size='xs' color='grey25'>
                Status
              </Text>
              <TextHighlight>
                {formatInvoiceStatusText(invoice?.status)}
              </TextHighlight>
            </div>
          </div>
          <div css={{ marginLeft: '45px' }}>
            <Text size='xs' color='grey25'>
              Invoice due on
            </Text>
            <TextHighlight>
              {formatDate(invoice?.dueDate, 'MMM DD, YYYY')}
            </TextHighlight>
          </div>
        </div>

        {invoice?.productName && (
          <div css={{ marginTop: '40px' }}>
            <TextHighlight>Invoice details</TextHighlight>
            <Divider color='grey15' margin={0} />
            <MultiLineText
              color='grey25'
              size='xs'
              styles={{ margin: '10px 0 0' }}
            >
              {invoice?.productName}
            </MultiLineText>
          </div>
        )}
      </div>
      <div css={{ marginTop: invoice?.productName ? '250px' : '290px' }}>
        <Divider color='grey15' margin={0} />
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextHighlight>Total due</TextHighlight>
          <Heading tag='h3' color='grey25' size='md'>
            {formatCurrency({ amount: invoice?.amount })}
          </Heading>
        </div>
      </div>
    </>
  )
}
export default InvoicePdfLayout
