import theme from 'utils/theme'

import { IDownloadPdf } from '../types'
const { colors } = theme

const Blob = ({
  brandColor,
  masthead
}: Pick<IDownloadPdf, 'brandColor' | 'masthead'>) => (
  <>
    <div
      css={{
        position: 'absolute',
        top: '125px',
        left: '40px',
        fontSize: '42px',
        lineHeight: '1.25',
        fontWeight: 600,
        color: !brandColor || brandColor?.isDark ? colors.light : colors.grey25
      }}
    >
      {masthead.circleContent}
    </div>
    <svg
      width='635'
      height='282'
      viewBox='0 0 635 282'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M588.764 -219.061C619.834 -71.934 541.184 84.4903 428.701 184.287C321.198 279.665 169.019 284.555 25.353 280.805C-113.319 277.186 -250.655 252.268 -357.476 163.767C-475.364 66.0971 -579.252 -65.9744 -577.989 -219.061C-576.733 -371.38 -455.529 -484.629 -351.212 -595.626C-241.882 -711.958 -131.817 -889.039 25.3531 -861.043C182.752 -833.006 182.59 -601.919 288.047 -481.756C379.316 -377.76 560.175 -354.441 588.764 -219.061Z'
        fill={brandColor?.hex || colors.primary}
      />
      <g opacity='0.15' clipPath='url(#clip0_6105_55923)'>
        <circle cx='40.5' cy='19.5' r='2.5' fill={colors.purple5} />
        <circle cx='93.5' cy='19.5' r='2.5' fill={colors.purple5} />
        <circle cx='146.5' cy='19.5' r='2.5' fill={colors.purple5} />
        <circle cx='199.5' cy='19.5' r='2.5' fill={colors.purple5} />
        <circle cx='252.5' cy='19.5' r='2.5' fill={colors.purple5} />
        <circle cx='305.5' cy='19.5' r='2.5' fill={colors.purple5} />
        <circle cx='40.5' cy='72.5' r='2.5' fill={colors.purple5} />
        <circle cx='93.5' cy='72.5' r='2.5' fill={colors.purple5} />
        <circle cx='146.5' cy='72.5' r='2.5' fill={colors.purple5} />
        <circle cx='199.5' cy='72.5' r='2.5' fill={colors.purple5} />
        <circle cx='252.5' cy='72.5' r='2.5' fill={colors.purple5} />
        <circle cx='305.5' cy='72.5' r='2.5' fill={colors.purple5} />
        <circle cx='40.5' cy='125.5' r='2.5' fill={colors.purple5} />
        <circle cx='93.5' cy='125.5' r='2.5' fill={colors.purple5} />
        <circle cx='146.5' cy='125.5' r='2.5' fill={colors.purple5} />
        <circle cx='199.5' cy='125.5' r='2.5' fill={colors.purple5} />
        <circle cx='252.5' cy='125.5' r='2.5' fill={colors.purple5} />
        <circle cx='305.5' cy='125.5' r='2.5' fill={colors.purple5} />
        <circle cx='40.5' cy='178.5' r='2.5' fill={colors.purple5} />
        <circle cx='93.5' cy='178.5' r='2.5' fill={colors.purple5} />
        <circle cx='146.5' cy='178.5' r='2.5' fill={colors.purple5} />
        <circle cx='199.5' cy='178.5' r='2.5' fill={colors.purple5} />
        <circle cx='252.5' cy='178.5' r='2.5' fill={colors.purple5} />
        <circle cx='305.5' cy='178.5' r='2.5' fill={colors.purple5} />
        <circle cx='40.5' cy='231.5' r='2.5' fill={colors.purple5} />
        <circle cx='93.5' cy='231.5' r='2.5' fill={colors.purple5} />
        <circle cx='146.5' cy='231.5' r='2.5' fill={colors.purple5} />
        <circle cx='199.5' cy='231.5' r='2.5' fill={colors.purple5} />
        <circle cx='252.5' cy='231.5' r='2.5' fill={colors.purple5} />
        <circle cx='305.5' cy='231.5' r='2.5' fill={colors.purple5} />
      </g>
      <g opacity='0.15' clipPath='url(#clip1_6105_55923)'>
        <circle cx='351.178' cy='19.5' r='2.5' fill={colors.purple5} />
        <circle cx='404.178' cy='19.5' r='2.5' fill={colors.purple5} />
        <circle cx='457.178' cy='19.5' r='2.5' fill={colors.purple5} />
        <circle cx='510.178' cy='19.5' r='2.5' fill={colors.purple5} />
        <circle cx='563.178' cy='19.5' r='2.5' fill={colors.purple5} />
        <circle cx='616.178' cy='19.5' r='2.5' fill={colors.purple5} />
        <circle cx='351.178' cy='72.5' r='2.5' fill={colors.purple5} />
        <circle cx='404.178' cy='72.5' r='2.5' fill={colors.purple5} />
        <circle cx='457.178' cy='72.5' r='2.5' fill={colors.purple5} />
        <circle cx='510.178' cy='72.5' r='2.5' fill={colors.purple5} />
        <circle cx='563.178' cy='72.5' r='2.5' fill={colors.purple5} />
        <circle cx='616.178' cy='72.5' r='2.5' fill={colors.purple5} />
        <circle cx='351.178' cy='125.5' r='2.5' fill={colors.purple5} />
        <circle cx='404.178' cy='125.5' r='2.5' fill={colors.purple5} />
        <circle cx='457.178' cy='125.5' r='2.5' fill={colors.purple5} />
        <circle cx='510.178' cy='125.5' r='2.5' fill={colors.purple5} />
        <circle cx='563.178' cy='125.5' r='2.5' fill={colors.purple5} />
        <circle cx='616.178' cy='125.5' r='2.5' fill={colors.purple5} />
        <circle cx='351.178' cy='178.5' r='2.5' fill={colors.purple5} />
        <circle cx='404.178' cy='178.5' r='2.5' fill={colors.purple5} />
        <circle cx='457.178' cy='178.5' r='2.5' fill={colors.purple5} />
        <circle cx='510.178' cy='178.5' r='2.5' fill={colors.purple5} />
        <circle cx='563.178' cy='178.5' r='2.5' fill={colors.purple5} />
        <circle cx='616.178' cy='178.5' r='2.5' fill={colors.purple5} />
        <circle cx='351.178' cy='231.5' r='2.5' fill={colors.purple5} />
        <circle cx='404.178' cy='231.5' r='2.5' fill={colors.purple5} />
        <circle cx='457.178' cy='231.5' r='2.5' fill={colors.purple5} />
        <circle cx='510.178' cy='231.5' r='2.5' fill={colors.purple5} />
        <circle cx='563.178' cy='231.5' r='2.5' fill={colors.purple5} />
        <circle cx='616.178' cy='231.5' r='2.5' fill={colors.purple5} />
      </g>
      <defs>
        <clipPath id='clip0_6105_55923'>
          <rect
            width='286.322'
            height='265'
            fill='white'
            transform='translate(38 17)'
          />
        </clipPath>
        <clipPath id='clip1_6105_55923'>
          <rect
            width='286.322'
            height='265'
            fill='white'
            transform='translate(348.678 17)'
          />
        </clipPath>
      </defs>
    </svg>
  </>
)

export default Blob
