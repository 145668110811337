import { memo, useEffect, useRef, useState } from 'react'
import { CSSProperties } from 'react'

import Download from '@carbon/icons-react/lib/Download'
import { OpenFeature } from '@openfeature/web-sdk'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Partner,
  CustomerFindInvoiceFragment,
  useCustomerGetInvoicePdfLinkLazyQuery,
  useGetPartnerAccountingPreferencesQuery,
  InvoicePdfStatus
} from 'graph/generated/payments/graphql-types'
import ContentWrapperV1 from 'lib/ui/DownloadPdf/ContentWrapper'
import PartnerDetails from 'lib/ui/DownloadPdf/PartnerDetails'
import * as cssV1 from 'lib/ui/DownloadPdf/styles'
import Text from 'lib/ui/Text'
import ToastMessage from 'lib/ui/Toast/v2'
import toast from 'react-hot-toast'
import { IReactToPrintProps, useReactToPrint } from 'react-to-print'
import { handleSegmentTracking } from 'utils/analytics'

import { formatIcon } from './styles'
import InvoicePdfLayoutV2 from './v2/InvoicePdfLayout'
import * as cssV2 from './v2/styles'
import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'
import Button, { IButtonProps } from '@/ui/Button/v2'
import InvoicePdfLayoutV1 from '@/ui/Pdfs/InvoicePdfLayout'

dayjs.extend(utc)

interface IInvoiceDownload {
  autoDownload?: boolean
  autoDownloadWithButton?: boolean
  btnProps?: IButtonProps
  rootStyles?: CSSProperties
  btnText?: string
  invoice: CustomerFindInvoiceFragment
  onDownloaded?: () => void
  showLink?: boolean
  showIcon?: boolean
  buttonSize?: 'sm' | 'md' | 'lg'
  ignoreMargin?: boolean
  buttonIcon?: JSX.Element
}

const InvoiceDownload = memo(
  ({
    autoDownload = false,
    autoDownloadWithButton = false,
    btnText,
    invoice,
    btnProps,
    rootStyles,
    onDownloaded,
    showLink = false,
    showIcon = true,
    buttonSize = 'md',
    ignoreMargin = false,
    buttonIcon
  }: IInvoiceDownload) => {
    const { data: preferences, loading: loadingPreferences } =
      useGetPartnerAccountingPreferencesQuery()

    const partner = invoice?.partner as Partner

    const [usePdfV2, setUsePdfV2] = useState(false)
    const client = OpenFeature.getClient()
    OpenFeature.setContext({
      targetingKey: partner?.id,
      platformName: invoice?.platformName
    })
    if (!usePdfV2 && client.getBooleanValue('ff-invoice-pdf-v2', false)) {
      setUsePdfV2(true)
    }

    const { brandColor } = useCompanyAndInvoiceContext()

    const componentRef = useRef(null)

    const [isPolling, setIsPolling] = useState(false)

    const [
      fetchInvoiceDownload,
      { data, loading: loadingInvoice, error, stopPolling }
    ] = useCustomerGetInvoicePdfLinkLazyQuery({
      variables: { invoiceID: invoice?.id },
      fetchPolicy: 'network-only',
      pollInterval: 3000 // 5 seconds
    })

    const handlePrintProps: IReactToPrintProps = {
      content: () => componentRef.current
    }
    if (usePdfV2) {
      handlePrintProps.pageStyle = cssV2.reactToPrintPageStyle
    }
    const handlePrint = useReactToPrint(handlePrintProps)

    useEffect(() => {
      if (loadingInvoice || (data === undefined && error === undefined)) return

      if (error) {
        if (error?.message === 'Internal Server Error') {
          toast.error(
            <ToastMessage
              type='error'
              title='Invoice PDF is not yet synchronized'
              subtitle='Please try again shortly'
            />,
            { id: 'error' }
          )
          return
        }

        toast.error(
          <ToastMessage
            type='error'
            title='Could not get invoice PDF'
            subtitle='Please try again shortly'
          />,
          { id: 'error' }
        )
        handleSegmentTracking({
          message: 'Error fetching invoice download',
          error
        })
        return
      }

      const status = data?.CustomerGetInvoicePdfLink?.status
      const url = data?.CustomerGetInvoicePdfLink?.url

      if (
        [InvoicePdfStatus.Pending, InvoicePdfStatus.Expired].includes(status)
      ) {
        return
      }

      if (status === InvoicePdfStatus.Failure) {
        stopPolling()
        setIsPolling(false)

        toast.error(
          <ToastMessage
            type='error'
            title='Could not get invoice PDF'
            subtitle='Please try again shortly'
          />,
          { id: 'error' }
        )
        return
      }

      if (status === InvoicePdfStatus.Success) {
        stopPolling()
        setIsPolling(false)

        if (url) {
          handleSegmentTracking({
            message: 'Invoice Downloaded',
            data: { invoice }
          })
          window.open(url, '_blank')
          if (onDownloaded) onDownloaded()
        } else {
          handlePrint()
        }
        return
      }
    }, [data, loadingInvoice, error])

    const handleDownload = async () => {
      if (preferences?.GetPartnerAccountingPreferences?.useIntegrationPDF) {
        try {
          setIsPolling(true)
          await fetchInvoiceDownload()
        } catch (error) {
          handleSegmentTracking({
            message: 'Error fetching invoice download',
            error
          })
        }
      } else {
        handlePrint()
      }
    }

    useEffect(() => {
      if (loadingPreferences) {
        return
      }

      if (autoDownload || autoDownloadWithButton) {
        handleDownload()
      }
    }, [autoDownload, autoDownloadWithButton, loadingPreferences])

    return (
      <>
        <div css={{ ...rootStyles }}>
          {(!autoDownload || autoDownloadWithButton) && (
            <Button
              onClick={handleDownload}
              loading={loadingInvoice || loadingPreferences || isPolling}
              variant='outline'
              buttonSize={buttonSize}
              iconPlacement='left'
              display='flex'
              {...btnProps}
            >
              {showIcon && (
                <span
                  css={{
                    ...(ignoreMargin
                      ? { '& svg': { margin: 0 } }
                      : { formatIcon })
                  }}
                >
                  {buttonIcon || <Download size={16} />}
                </span>
              )}
              {btnText && <Text>{btnText}</Text>}
            </Button>
          )}
          {showLink && data?.CustomerGetInvoicePdfLink?.url && (
            <Text size='sm' margin={[0.5, 0, 0]}>
              If you have trouble downloading the invoice you can click {''}
              <a href={data?.CustomerGetInvoicePdfLink?.url}>this link</a>.
            </Text>
          )}
        </div>

        <div
          ref={componentRef}
          css={usePdfV2 ? cssV2.showOnlyOnPrint : cssV1.showOnlyOnPrint}
        >
          {!usePdfV2 && (
            <ContentWrapperV1
              brandColor={brandColor}
              masthead={{
                circleContent: (
                  <>
                    Invoice
                    <div css={{ fontSize: '32px' }}>
                      #{invoice?.invoiceNumber}
                    </div>
                  </>
                ),
                logo: partner?.paymentsPortalCustomization?.logoUrl,
                logoContent: <PartnerDetails partner={partner} />
              }}
            >
              <InvoicePdfLayoutV1 invoice={invoice} />
            </ContentWrapperV1>
          )}
          {usePdfV2 && (
            <InvoicePdfLayoutV2
              partner={partner}
              invoice={invoice}
              brandColor={brandColor}
            />
          )}
        </div>
      </>
    )
  }
)

InvoiceDownload.displayName = 'InvoiceDownload'

export default InvoiceDownload
