import { useRef } from 'react'

import Download from '@carbon/icons-react/lib/Download'
import ReactToPrint from 'react-to-print'

import ContentWrapper from './ContentWrapper'
import * as downloadPdfCss from './styles'
import { IDownloadPdf } from './types'
import ContentWrapperV2 from './v2/ContentWrapper'
import Button from '../Button'
export { downloadPdfCss }
export type { IDownloadPdf }

const DownloadPdf = ({
  brandColor,
  buttonProps,
  buttonText = 'Download .PDF',
  children,
  masthead,
  pageCounter,
  useV2,
  customFooter,
  width,
  showIcon = true
}: IDownloadPdf) => {
  const printRef = useRef()

  return (
    <>
      <div style={{ width: width ?? '240px' }}>
        <ReactToPrint
          trigger={() => (
            <Button
              brandColor={brandColor}
              variant='outline'
              buttonSize='md'
              iconPlacement='left'
              display='flex'
              {...buttonProps}
            >
              {!buttonProps?.children ? (
                <>
                  {showIcon && <Download size={16} />}
                  {buttonText}
                </>
              ) : (
                <>{buttonProps?.children}</>
              )}
            </Button>
          )}
          content={() => printRef.current}
          /* used margin: -1px to remove the white line that stays between the sticky the header and the right side of the page */
          pageStyle={`
            @page {
              margin: -1px;
            }
            @media print {
              body {
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
              }
            }
          `}
        />
      </div>

      <div ref={printRef} css={downloadPdfCss.showOnlyOnPrint}>
        {useV2 ? (
          <ContentWrapperV2 brandColor={brandColor} customFooter={customFooter}>
            {children}
          </ContentWrapperV2>
        ) : (
          <ContentWrapper
            brandColor={brandColor}
            masthead={masthead}
            pageCounter={pageCounter}
          >
            {children}
          </ContentWrapper>
        )}
      </div>
    </>
  )
}

export default DownloadPdf
