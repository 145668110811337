import Blob from './Decorations/Blob'
import Logo from './Decorations/Logo'
import PoweredBy from './Decorations/PoweredBy'
import QuarterCircleGrey from './Decorations/QuarterCircleGrey'
import * as downloadPdfCss from './styles'
import { IDownloadPdf } from './types'

const Content = ({
  children,
  brandColor,
  masthead,
  pageCounter
}: Pick<
  IDownloadPdf,
  'brandColor' | 'children' | 'masthead' | 'pageCounter'
>) => (
  <>
    {masthead && (
      <>
        <Blob brandColor={brandColor} masthead={masthead} />
        <Logo masthead={masthead} />
      </>
    )}

    <div css={{ ...downloadPdfCss.content }}>
      <div css={{ ...downloadPdfCss.contentColumn }}>{children}</div>
    </div>
    {pageCounter && (
      <div
        css={{
          position: 'absolute',
          right: 40,
          bottom: 40,
          fontSize: '14px',
          zIndex: 1
        }}
      >
        {pageCounter}
      </div>
    )}
    {masthead?.logo && <PoweredBy />}
    <QuarterCircleGrey />
  </>
)

export default Content
