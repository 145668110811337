import theme from 'utils/theme'

const GreyBox = ({ children }: { children: React.ReactNode }) => (
  <div
    css={{
      padding: theme.spacing(0.75),
      background: theme.colors.grey5,
      borderRadius: theme.radii.lg,
      minWidth: '250px'
    }}
  >
    {children}
  </div>
)

export default GreyBox
