import { CSSProperties } from 'react'

import { createStyles } from 'utils/css'

import { IMultiLineTextStyles } from './MultiLineText.types'

export const root = ({
  alignment,
  color,
  margin,
  size,
  styles
}: IMultiLineTextStyles) =>
  createStyles(({ alignments, colors, fontSizes, spacing, treatments }) => {
    const defaultStyles: CSSProperties = {
      ...treatments.body,
      color: colors[color] || color,
      fontSize: fontSizes[size],
      fontWeight: 'inherit',
      textAlign: alignments[alignment] as CSSProperties['textAlign'],
      margin: margin && spacing(margin),
      whiteSpace: 'pre-line'
    }

    return { ...defaultStyles, ...styles }
  })
