import * as css from './styles'
import { ITextHighlight } from './types'
import Text from '../Text'

const TextHighlight = ({
  children,
  color = 'grey25',
  size = 'md'
}: ITextHighlight) => (
  <Text color={color} size={size} styles={css.fontStyles}>
    {children}
  </Text>
)

export default TextHighlight
